import colors from 'tailwindcss/colors'

// This fix deprecated colors
// More info: https://github.com/tailwindlabs/tailwindcss/issues/4690#issuecomment-1046087220
const tailwindcssColors = Object.keys(colors)
  .filter((color) => !['lightBlue', 'warmGray', 'trueGray', 'coolGray', 'blueGray'].includes(color))
  .map((color) => ({ [color]: colors[color as keyof typeof colors] }))
  .reduce((acc, curr) => ({ ...acc, ...curr }), {})

const internalColors = [
  'rose',
  'pink',
  'fuchsia',
  'purple',
  'violet',
  'indigo',
  'blue',
  'lightBlue',
  'cyan',
  'teal',
  'emerald',
  'green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'red',
  'warmGray',
  'trueGray',
  'gray',
  'coolGray',
  'blueGray',
]

const variants = Array.from({ length: 10 }, (_, i) => (i + 1) * 100)

export const colorsSafelist = (): string[] => {
  const safelist = ['black', 'white']

  internalColors.forEach((color) => {
    variants.forEach((variant) => {
      safelist.push(`${color}-${variant}`)
    })
  })

  return safelist
}

export const getColorReport = (key: string): string => {
  const colorsReports: Record<string, string> = {
    OPENNESS: '#DC408E',
    CONSCIENTIOUSNESS: '#A5BF45',
    EXTRAVERSION: '#F27830',
    AGREEABLENESS: '#5BBBA8',
    NEUROTICISM: '#D93E30',
    INTELLECT: '#821A4E',
    ADVENTUROUSNESS: '#A72064',
    EMOTIONALITY: '#CB2679',
    LIBERALISM: '#DC408E',
    ARTISTIC_INTERESTS: '#E363A3',
    IMAGINATION: '#EA87B9',
    ACHIEVEMENT_STRIVING: '#546124',
    SELF_EFFICACY: '#6F812E',
    ORDERLINESS: '#8BA139',
    DUTIFULNESS: '#A5BF45',
    CAUTIOUSNESS: '#B5CB64',
    SELF_DISCIPLINE: '#C4D683',
    OUTGOING: '#96400D',
    GREGARIOUSNESS: '#BE500F',
    ASSERTIVENESS: '#E66011',
    CHEERFULNESS: '#F17831',
    EXCITEMENT_SEEKING: '#F49258',
    ACTIVITY_LEVEL: '#F7AC80',
    SYMPATHY: '#2E695D',
    MODESTY: '#3A8778',
    TRUST: '#46A593',
    MORALITY: '#5BBBA8',
    COOPERATION: '#78C8B8',
    ALTRUISM: '#96D5C9',
    ANXIETY: '#721F17',
    VULNERABILITY: '#97271D',
    SELF_CONSCIOUSNESS: '#BB3023',
    DEPRESSION: '#D93E30',
    IMMODERATION: '#E05F53',
    ANGER: '#E88076',
  }

  return colorsReports[key.toUpperCase()]
}

export const colorListForGraphics = {
  blueviolet: { 100: '#eccff9', 500: '#691191' },
  brown: { 100: '#FFD5AA', 500: '#FF9021' },
  chartreuse: { 100: '#F0FF8A', 500: '#DFFF00' },
  chocolate: { 100: '#FFC98F', 500: '#FF8506' },
  coral: { 100: '#fed8c9', 500: '#C53500' },
  crimson: { 100: '#faced7', 500: '#DC143C' },
  gold: { 100: '#fef6c9', 500: '#FFD700' },
  grey: { 100: '#C5C5C5', 500: '#808080' },
  ivory: { 100: '#fefec9', 500: '#FFFF66' },
  khaki: { 100: '#F0E68C', 500: '#D6C41C' },
  lavender: { 100: '#E6E6FA', 500: '#7373E3' },
  magenta: { 100: '#fec9fe', 500: '#FF00FF' },
  maroon: { 100: '#fec9c9', 500: '#FF0B0B' },
  midnightblue: { 100: '#AFAFED', 500: '#3F3FD4' },
  mintcream: { 100: '#d7f1e7', 500: '#3EB489' },
  navy: { 100: '#c9c9fe', 500: '#0B0BFF' },
  olive: { 100: '#FFFF94', 500: '#FFFF0B' },
  orchid: { 100: '#f4d4f2', 500: '#992894' },
  royalblue: { 100: '#d1daf7', 500: '#4169E1' },
  salmon: { 100: '#fdd0cb', 500: '#DB1D08' },
  seashell: { 100: '#fedfc9', 500: '#ff6c06' },
  sienna: { 100: '#f3dfd5', 500: '#a5542e' },
  silver: { 100: '#e4e4e4', 500: '#7B7B7B' },
  tan: { 100: '#f0e6d8', 500: '#99713C' },
  whitesmoke: { 100: '#e4e4e4', 500: '#B0B0B0' },
  darkorchid: { 100: '#f6eafd', 500: '#ba61eb' },
  darksalmon: { 100: '#fbebe5', 500: '#dd7856' },
  mediumaquamarine: { 100: '#d7f4e7', 500: '#29a081' },
  peru: { 100: '#f4e9d1', 500: '#cd863f' },
  cadetblue: { 100: '#daedec', 500: '#468386' },
  cornflowerblue: { 100: '#dee7fb', 500: '#4a77e7' },
  papayawhip: { 100: '#ffefd5', 500: '#f97e16' },
  springgreen: { 100: '#d5ffeb', 500: '#00ff80' },
  lightsalmon: { 100: '#ffe3d4', 500: '#fc3e13' },
  darkorange: { 100: '#fff6d3', 500: '#ffa40a' },
  aliceblue: {
    100: '#e0f0fe',
    500: '#0e96e9',
  },
  antiquewhite: {
    100: '#faebd7',
    500: '#e0782f',
  },
  aqua: {
    100: '#c0feff',
    500: '#00e1e2',
  },
  aquamarine: {
    100: '#cbffeb',
    500: '#00d4a4',
  },
  azure: {
    100: '#cffcfe',
    500: '#06c1d4',
  },
  beige: {
    100: '#f5f5dc',
    500: '#baa538',
  },
  bisque: {
    100: '#ffe4c4',
    500: '#f96c16',
  },
  blanchedalmond: {
    100: '#ffebcd',
    500: '#f97916',
  },
  burlywood: {
    100: '#f6edde',
    500: '#ca8345',
  },
  cornsilk: {
    100: '#fff4c0',
    500: '#f49d00',
  },
  darkblue: {
    100: '#e5e8ff',
    500: '#3f42ff',
  },
  darkcyan: {
    100: '#c5fffa',
    500: '#00d0c9',
  },
  darkgoldenrod: {
    100: '#fcf9c5',
    500: '#e1b711',
  },
  darkgray: {
    100: '#ededed',
    500: '#999999',
  },
  darkgreen: {
    100: '#ccffbb',
    500: '#06ff00',
  },
  darkgrey: {
    100: '#ededed',
    500: '#999999',
  },
  darkkhaki: {
    100: '#ecedd8',
    500: '#aea052',
  },
  darkmagenta: {
    100: '#ffe1fe',
    500: '#ff16f4',
  },
  darkolivegreen: {
    100: '#eaf0d7',
    500: '#809e44',
  },
  darkred: {
    100: '#ffdada',
    500: '#ff1111',
  },
  darkseagreen: {
    100: '#e0edde',
    500: '#47824b',
  },
  darkslateblue: {
    100: '#e4e7fb',
    500: '#7d77dd',
  },
  darkslategray: {
    100: '#dcebe9',
    500: '#4b817f',
  },
  darkslategrey: {
    100: '#dcebe9',
    500: '#4b817f',
  },
  darkturquoise: {
    100: '#c6fffb',
    500: '#00ced1',
  },
  darkviolet: {
    100: '#f7e2ff',
    500: '#c92dff',
  },
  deeppink: {
    100: '#ffe3f6',
    500: '#ff27ac',
  },
  deepskyblue: {
    100: '#def4ff',
    500: '#00bfff',
  },
  dimgray: {
    100: '#e7e7e7',
    500: '#696969',
  },
  dimgrey: {
    100: '#e7e7e7',
    500: '#696969',
  },
  dodgerblue: {
    100: '#d7f1ff',
    500: '#28a6ff',
  },
  firebrick: {
    100: '#fde3e3',
    500: '#e84b4b',
  },
  floralwhite: {
    100: '#fff2d5',
    500: '#f98c16',
  },
  forestgreen: {
    100: '#e0f9df',
    500: '#34b433',
  },
  gainsboro: {
    100: '#ededed',
    500: '#999999',
  },
  ghostwhite: {
    100: '#e9e9fe',
    500: '#715cf6',
  },
  goldenrod: {
    100: '#f8f2c9',
    500: '#daa520',
  },
  greenyellow: {
    100: '#edffc4',
    500: '#84e600',
  },
  honeydew: {
    100: '#d7ffd9',
    500: '#09de15',
  },
  hotpink: {
    100: '#fee5f2',
    500: '#fa3a95',
  },
  indianred: {
    100: '#f9e7e7',
    500: '#cd5c5c',
  },
  lavenderblush: {
    100: '#ffe3ee',
    500: '#ff2a6c',
  },
  lawngreen: {
    100: '#e8ffc5',
    500: '#7efc00',
  },
  lemonchiffon: {
    100: '#fffacd',
    500: '#eeb104',
  },
  lightblue: {
    100: '#e7f2f7',
    500: '#3e9cb9',
  },
  lightcoral: {
    100: '#fce4e4',
    500: '#e44f4f',
  },
  lightcyan: {
    100: '#cffcfe',
    500: '#06c1d4',
  },
  lightgoldenrodyellow: {
    100: '#fafad2',
    500: '#d6b91c',
  },
  lightgray: {
    100: '#ededed',
    500: '#999999',
  },
  lightgreen: {
    100: '#defbdd',
    500: '#25c226',
  },
  lightgrey: {
    100: '#ededed',
    500: '#999999',
  },
  lightpink: {
    100: '#ffe3e6',
    500: '#f83b60',
  },
  lightseagreen: {
    100: '#cff8f2',
    500: '#20b2ab',
  },
  lightskyblue: {
    100: '#e1f0fd',
    500: '#1296e5',
  },
  lightslategray: {
    100: '#ecf1f3',
    500: '#98aebc',
  },
  lightslategrey: {
    100: '#ecf1f3',
    500: '#98aebc',
  },
  lightsteelblue: {
    100: '#e5eaf4',
    500: '#718cc0',
  },
  lightyellow: {
    100: '#fdfec9',
    500: '#c2d40e',
  },
  limegreen: {
    100: '#defade',
    500: '#32cd32',
  },
  linen: {
    100: '#faf0e6',
    500: '#d36f3c',
  },
  mediumblue: {
    100: '#e4e9ff',
    500: '#3a43ff',
  },
  mediumorchid: {
    100: '#f7ebfc',
    500: '#ba55d3',
  },
  mediumpurple: {
    100: '#efecfb',
    500: '#9470db',
  },
  mediumseagreen: {
    100: '#d6f5df',
    500: '#23a663',
  },
  mediumslateblue: {
    100: '#e1e2fe',
    500: '#7c68ee',
  },
  mediumspringgreen: {
    100: '#d5ffef',
    500: '#00fa9a',
  },
  mediumturquoise: {
    100: '#cef9f4',
    500: '#1cb0ad',
  },
  mediumvioletred: {
    100: '#fce7f8',
    500: '#f044bf',
  },
  mistyrose: {
    100: '#ffe4e1',
    500: '#f5503e',
  },
  moccasin: {
    100: '#fff1d5',
    500: '#fc8a13',
  },
  navajowhite: {
    100: '#fff0d5',
    500: '#fc8113',
  },
  oldlace: {
    100: '#fcf0d8',
    500: '#e88927',
  },
  olivedrab: {
    100: '#eaf9d9',
    500: '#89b230',
  },
  orangered: {
    100: '#ffead3',
    500: '#ff5e0a',
  },
  palegoldenrod: {
    100: '#f5f2cc',
    500: '#c6a52c',
  },
  palegreen: {
    100: '#daffd9',
    500: '#11d613',
  },
  paleturquoise: {
    100: '#d6f7f7',
    500: '#25afb5',
  },
  palevioletred: {
    100: '#f9eaf0',
    500: '#db7093',
  },
  peachpuff: {
    100: '#ffead5',
    500: '#fc6413',
  },
  plum: {
    100: '#f8eef9',
    500: '#c66fc5',
  },
  powderblue: {
    100: '#d9f1f4',
    500: '#3495a6',
  },
  rosybrown: {
    100: '#f4ecec',
    500: '#bc8f8f',
  },
  saddlebrown: {
    100: '#fcf4c9',
    500: '#eca414',
  },
  sandybrown: {
    100: '#fdebd7',
    500: '#ed6b22',
  },
  seagreen: {
    100: '#d6f5e2',
    500: '#1cb08c',
  },
  skyblue: {
    100: '#e4f2fa',
    500: '#2aa2cd',
  },
  slateblue: {
    100: '#e4e7fb',
    500: '#7d78dc',
  },
  slategray: {
    100: '#ecf2f3',
    500: '#9bacb9',
  },
  slategrey: {
    100: '#ecf2f3',
    500: '#9bacb9',
  },
  snow: {
    100: '#ffe0e0',
    500: '#fd3636',
  },
  steelblue: {
    100: '#e8eef6',
    500: '#4682b4',
  },
  thistle: {
    100: '#f4eff4',
    500: '#b183af',
  },
  tomato: {
    100: '#ffe4df',
    500: '#ff6347',
  },
  turquoise: {
    100: '#cafdf3',
    500: '#0cc0b1',
  },
  wheat: {
    100: '#fbf0da',
    500: '#e18c2e',
  },
  yellowgreen: {
    100: '#ecf7d0',
    500: '#86b82a',
  },
  primary: {
    100: '#D6F0F5',
    200: '#B2E0E9',
    300: '#8BC8D5',
    400: '#6BACC1',
    500: '#559AB5',
    600: '#4588AA',
    700: '#31729C',
    800: '#21608F',
    900: '#134A7D',
  },
  ...tailwindcssColors,
}
