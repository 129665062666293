export const API_CODE_ERRORS = Object.freeze({
  UNAUTHORIZED: 401,
  FREE_LIMIT_EXCEEDED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  PERSON_YEAR_BIRTH_MISSING: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
})

export const API_ERRORS = Object.freeze({
  [API_CODE_ERRORS.UNAUTHORIZED]: 'Unauthorized',
  [API_CODE_ERRORS.FREE_LIMIT_EXCEEDED]: 'Límite de usos alcanzado',
  [API_CODE_ERRORS.FORBIDDEN]: 'Forbidden',
  [API_CODE_ERRORS.NOT_FOUND]: 'Not found',
  [API_CODE_ERRORS.PERSON_YEAR_BIRTH_MISSING]: 'PERSON_YEAR_BIRTH_MISSING',
  [API_CODE_ERRORS.INTERNAL_SERVER_ERROR]: 'Internal server error',
  [API_CODE_ERRORS.BAD_GATEWAY]: 'Bad gateway',
  [API_CODE_ERRORS.SERVICE_UNAVAILABLE]: 'Service unavailable',
  [API_CODE_ERRORS.GATEWAY_TIMEOUT]: 'Gateway timeout',
})

export const API_STATUS_TYPES = Object.freeze({
  OK: 'OK',
  KO: 'KO',
})
