import colorLib from '@kurkle/color'

const COLORS = ['#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba']

export function color(index: number): string {
  return COLORS[index % COLORS.length]
}

export function transparentize(value: string, opacity: number): string {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity

  try {
    return colorLib(value).alpha(alpha).rgbString()
  } catch (err) {
    const randomIndex = Math.floor(Math.random() * COLORS.length)
    return colorLib(COLORS[randomIndex]).alpha(alpha).rgbString()
  }
}

export const CHART_COLORS: Record<string, string> = {
  red: 'rgb(239, 68, 68)',
  orange: 'rgb(249, 115, 22)',
  yellow: 'rgb(234, 179, 8)',
  lime: 'rgb(132, 204, 22)',
  green: 'rgb(34, 197, 94)',
  teal: 'rgb(20, 184, 166)',
  cyan: 'rgb(6, 182, 212)',
  blue: 'rgb(59, 130, 246)',
  indigo: 'rgb(99, 102, 241)',
  violet: 'rgb(139, 92, 246)',
  purple: 'rgb(168, 85, 247)',
  pink: 'rgb(236, 72, 153)',
}

export function namedColor(index: string, random = false): any {
  if (random) {
    const randomIndex = Math.floor(Math.random() * COLORS.length)
    index = randomIndex.toString()
    return COLORS[randomIndex]
  }

  return CHART_COLORS[index]
}
