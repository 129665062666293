// Components
import dayjs from 'dayjs'
import { toast, type TypeOptions } from 'react-toastify'

// Services
import HumanAPI from '@/services/index'

// Utils
import { type PLOT_TYPES } from '@/constants/graphics'
import type {
  IFactorFormatted,
  IFactorIterator,
  IFactorIteratorChildrens,
  IFactors,
  IFactorShort,
  IHandleResponse,
  IProfileGraph,
  ITraitIterator,
} from '@/types'
import { transparentize } from '@/utils/utilsChartjs'
import { type GetServerSidePropsContext } from 'next'
import { getToken, type JWT } from 'next-auth/jwt'
import { type TFunction } from 'next-i18next'

// Constants
import { API_ERRORS } from '@/constants/api'
import { colorListForGraphics } from '@/constants/colors'
import { ERROR_API_HANDLING } from '@/constants/errors'

export const fetcher = async (
  url: string,
  method = 'GET',
  body = {},
  headers = {},
  jsonBody = true
): Promise<Response> => {
  const _headers = {
    ...headers,
    'Content-Type': jsonBody ? 'application/json' : 'application/x-www-form-urlencoded',
  }

  const response =
    method === 'GET'
      ? await fetch(url, {
          method,
          headers: _headers,
        })
      : await fetch(url, {
          method,
          body: jsonBody ? JSON.stringify(body) : new URLSearchParams(body),
          headers: _headers,
        })

  return responseHandler(response)
}

const responseHandler = (response: Response): Response => {
  const { status } = response

  if (status >= 400) {
    const handler = ERROR_API_HANDLING[status as keyof typeof ERROR_API_HANDLING] ?? ERROR_API_HANDLING[0]

    handler({ message: API_ERRORS[status as keyof typeof API_ERRORS] })
  }

  return response
}

export const handlerError = (err: any): IHandleResponse => {
  return {
    status: 'KO',
    statusCode: err?.response?.status || err.statusCode || 500,
    error: err,
    data: null,
  }
}

// The disabled colors are the ones which pyDantic does not support
export const colorList = [
  'aliceblue',
  'antiquewhite',
  'aqua',
  'aquamarine',
  'azure',
  'beige',
  'bisque',
  // 'black',
  'blanchedalmond',
  'blue',
  'blueviolet',
  'brown',
  'burlywood',
  'cadetblue',
  'chartreuse',
  'chocolate',
  'coral',
  'cornflowerblue',
  'cornsilk',
  'crimson',
  'cyan',
  'darkblue',
  'darkcyan',
  'darkgoldenrod',
  'darkgray',
  'darkgreen',
  'darkgrey',
  'darkkhaki',
  'darkmagenta',
  'darkolivegreen',
  'darkorange',
  'darkorchid',
  'darkred',
  'darksalmon',
  'darkseagreen',
  'darkslateblue',
  'darkslategray',
  'darkslategrey',
  'darkturquoise',
  'darkviolet',
  'deeppink',
  'deepskyblue',
  'dimgray',
  'dimgrey',
  'dodgerblue',
  'firebrick',
  'floralwhite',
  'forestgreen',
  'fuchsia',
  'gainsboro',
  'ghostwhite',
  'gold',
  'goldenrod',
  'gray',
  'green',
  'greenyellow',
  'grey',
  'honeydew',
  'hotpink',
  'indianred',
  'indigo',
  'ivory',
  'khaki',
  'lavender',
  'lavenderblush',
  'lawngreen',
  'lemonchiffon',
  'lightblue',
  'lightcoral',
  'lightcyan',
  'lightgoldenrodyellow',
  'lightgray',
  'lightgreen',
  'lightgrey',
  'lightpink',
  'lightsalmon',
  'lightseagreen',
  'lightskyblue',
  'lightslategray',
  'lightslategrey',
  'lightsteelblue',
  'lightyellow',
  'lime',
  'limegreen',
  'linen',
  'magenta',
  'maroon',
  'mediumaquamarine',
  'mediumblue',
  'mediumorchid',
  'mediumpurple',
  'mediumseagreen',
  'mediumslateblue',
  'mediumspringgreen',
  'mediumturquoise',
  'mediumvioletred',
  'midnightblue',
  'mintcream',
  'mistyrose',
  'moccasin',
  'navajowhite',
  'navy',
  'oldlace',
  'olive',
  'olivedrab',
  'orange',
  'orangered',
  'orchid',
  'palegoldenrod',
  'palegreen',
  'paleturquoise',
  'palevioletred',
  'papayawhip',
  'peachpuff',
  'peru',
  'pink',
  'plum',
  'powderblue',
  'purple',
  'red',
  'rosybrown',
  'royalblue',
  'saddlebrown',
  'salmon',
  'sandybrown',
  'seagreen',
  'seashell',
  'sienna',
  'silver',
  'skyblue',
  'slateblue',
  'slategray',
  'slategrey',
  'snow',
  'springgreen',
  'steelblue',
  'tan',
  'teal',
  'thistle',
  'tomato',
  'turquoise',
  'violet',
  'wheat',
  'white',
  'whitesmoke',
  'yellow',
  'yellowgreen',
]

export const colorTranslation: Record<string, Record<string, string>> = {
  es: {
    aliceblue: 'Azul Alicia',
    amber: 'Ámbar',
    antiquewhite: 'Blanco antiguo',
    aqua: 'Agua',
    aquamarine: 'Aguamarina',
    azure: 'Azul Cielo',
    beige: 'Beige',
    bisque: 'Crema',
    black: 'Negro',
    blanchedalmond: 'Blanco Almendra',
    blue: 'Azul',
    blueviolet: 'Azul Violeta',
    brown: 'Marrón',
    burlywood: 'Madera',
    cadetblue: 'Azul Cadete',
    chartreuse: 'Verde Cartujo',
    chocolate: 'Marrón Chocolate',
    coral: 'Coral',
    cornflowerblue: 'Azúl Centaurea',
    cornsilk: 'Vainilla',
    crimson: 'Carmesí',
    cyan: 'Azul Turquesa',
    darkblue: 'Azul Oscuro',
    darkcyan: 'Verde Azulado',
    darkgoldenrod: 'Dorado Oscuro',
    darkgray: 'Gris Oscuro',
    darkgreen: 'Verde Oscuro',
    darkgrey: 'Gris Oscuro',
    darkkhaki: 'Beige Oscuro',
    darkmagenta: 'Púrpura Oscuro',
    darkolivegreen: 'Verde Oliva Oscuro',
    darkorange: 'Naranja Oscuro',
    darkorchid: 'Púrpura Oscuro',
    darkred: 'Rojo Oscuro',
    darksalmon: 'Salmón Oscuro',
    darkseagreen: 'Verde Mar Oscuro',
    darkslateblue: 'Azul Pizarra Oscuro',
    darkslategray: 'Gris Pizarra Oscuro',
    darkslategrey: 'Gris Pizarra Oscuro',
    darkturquoise: 'Azul Turquesa Oscuro',
    darkviolet: 'Púrpura Oscuro',
    deeppink: 'Rosa Intenso',
    deepskyblue: 'Azul Cielo Intenso',
    dimgray: 'Gris Intenso',
    dimgrey: 'Gris Intenso',
    dodgerblue: 'Azul Claro Intenso',
    emerald: 'Verde Esmeralda',
    firebrick: 'Ladrillo',
    floralwhite: 'Blanco Floral',
    forestgreen: 'Verde Bosque',
    fuchsia: 'Fucsia',
    gainsboro: 'Gris Claro',
    ghostwhite: 'Blanco Fantasma',
    gold: 'Oro',
    goldenrod: 'Dorado',
    gray: 'Gris',
    green: 'Verde',
    greenyellow: 'Verde Amarillo',
    grey: 'Gris',
    honeydew: 'Miel',
    hotpink: 'Rosa Intenso',
    indianred: 'Rojo Índico',
    indigo: 'Añil',
    ivory: 'Marfil',
    khaki: 'Caqui',
    lavender: 'Lavanda',
    lavenderblush: 'Lavanda Rosado',
    lawngreen: 'Verde Césped',
    lemonchiffon: 'Amarillo Limón',
    lightblue: 'Azul Claro',
    lightcoral: 'Coral Claro',
    lightcyan: 'Azul Turquesa Claro',
    lightgoldenrodyellow: 'Dorado Claro',
    lightgray: 'Gris Claro',
    lightgreen: 'Verde Claro',
    lightgrey: 'Gris Claro',
    lightpink: 'Rosa Claro',
    lightsalmon: 'Salmón Claro',
    lightseagreen: 'Verde Mar Claro',
    lightskyblue: 'Azul Cielo Claro',
    lightslategray: 'Gris Pizarra Claro',
    lightslategrey: 'Gris Pizarra Claro',
    lightsteelblue: 'Azul Acero Claro',
    lightyellow: 'Amarillo Claro',
    lime: 'Verde lima',
    limegreen: 'Verde Lima',
    linen: 'Lino',
    magenta: 'Magenta',
    maroon: 'Granate',
    mediumaquamarine: 'Aguamarina Medio',
    mediumblue: 'Azul Medio',
    mediumorchid: 'Orquídea Medio',
    mediumpurple: 'Morado Medio',
    mediumseagreen: 'Verde Mar Medio',
    mediumslateblue: 'Azul Pizarra Medio',
    mediumspringgreen: 'Verde Primavera Medio',
    mediumturquoise: 'Azul Turquesa Medio',
    mediumvioletred: 'Violeta Medio',
    midnightblue: 'Azul Medianoche',
    mintcream: 'Menta',
    mistyrose: 'Rosa Pálido',
    moccasin: 'Marrón Claro',
    navajowhite: 'Blanco Navajo',
    navy: 'Azul Marino',
    neutral: 'Gris',
    oldlace: 'Encaje',
    olive: 'Oliva',
    olivedrab: 'Oliva Pálido',
    orange: 'Naranja',
    orangered: 'Rojo Anaranjado',
    orchid: 'Orquídea',
    palegoldenrod: 'Oro Pálido',
    palegreen: 'Verde Pálido',
    paleturquoise: 'Turquesa Pálido',
    palevioletred: 'Violeta Pálido',
    papayawhip: 'Papaya',
    peachpuff: 'Melocotón',
    peru: 'Perú',
    pink: 'Rosado',
    plum: 'Ciruela',
    powderblue: 'Azul Polvo',
    purple: 'Morado',
    red: 'Rojo',
    rose: 'Rosa',
    rosybrown: 'Marrón Rosado',
    royalblue: 'Azul Real',
    saddlebrown: 'Marrón Silla',
    salmon: 'Salmón',
    sandybrown: 'Marrón Arena',
    seagreen: 'Verde Mar',
    seashell: 'Concha Marina',
    sienna: 'Tierra',
    silver: 'Plata',
    sky: 'Azul Cielo',
    skyblue: 'Azul Cielo',
    slate: 'Gris',
    slateblue: 'Azul Grisáceo',
    slategray: 'Gris Pizarra',
    slategrey: 'Gris Azulado',
    snow: 'Nieve',
    springgreen: 'Verde Primavera',
    steelblue: 'Azul Acero',
    stone: 'Gris',
    tan: 'Canela',
    teal: 'Azul Cerceta',
    thistle: 'Cardo',
    tomato: 'Rojo Tomate',
    turquoise: 'Turquesa',
    violet: 'Violeta',
    wheat: 'Amarillo Trigo',
    white: 'Blanco',
    whitesmoke: 'Blanco Humo',
    yellow: 'Amarillo',
    yellowgreen: 'Verde Amarillado',
    zinc: 'Gris',
  },
  en: {
    aliceblue: 'Aliceblue',
    amber: 'Amber',
    antiquewhite: 'Antique White',
    aqua: 'Aqua',
    aquamarine: 'Aquamarine',
    azure: 'Azure',
    beige: 'Beige',
    bisque: 'Bisque',
    black: 'Black',
    blanchedalmond: 'Blanched Almond',
    blue: 'Blue',
    blueviolet: 'Blue Violet',
    brown: 'Brown',
    burlywood: 'Burlywood',
    cadetblue: 'Cadet Blue',
    chartreuse: 'Chartreuse',
    chocolate: 'Chocolate',
    coral: 'Coral',
    cornflowerblue: 'Cornflower Blue',
    cornsilk: 'Cornsilk',
    crimson: 'Crimson',
    cyan: 'Cyan',
    darkblue: 'Dark Blue',
    darkcyan: 'Dark Cyan',
    darkgoldenrod: 'Dark Goldenrod',
    darkgray: 'Dark Gray',
    darkgreen: 'Dark Green',
    darkgrey: 'Dark Grey',
    darkkhaki: 'Dark Khaki',
    darkmagenta: 'Dark Magenta',
    darkolivegreen: 'Dark Olive Green',
    darkorange: 'Dark Orange',
    darkorchid: 'Dark Orchid',
    darkred: 'Dark Red',
    darksalmon: 'Dark Salmon',
    darkseagreen: 'Dark Sea Green',
    darkslateblue: 'Dark Slate Blue',
    darkslategray: 'Dark Slate Gray',
    darkslategrey: 'Dark Slate Grey',
    darkturquoise: 'Dark Turquoise',
    darkviolet: 'Dark Violet',
    deeppink: 'Deep Pink',
    deepskyblue: 'Deep Sky Blue',
    dimgray: 'Dim Gray',
    dimgrey: 'Dim Grey',
    dodgerblue: 'Dodger Blue',
    emerald: 'Emerald',
    firebrick: 'Firebrick',
    floralwhite: 'Floral White',
    forestgreen: 'Forest Green',
    fuchsia: 'Fuchsia',
    gainsboro: 'Gainsboro',
    ghostwhite: 'Ghost White',
    gold: 'Gold',
    goldenrod: 'Goldenrod',
    gray: 'Gray',
    green: 'Green',
    greenyellow: 'Green Yellow',
    grey: 'Grey',
    honeydew: 'Honeydew',
    hotpink: 'Hot Pink',
    indianred: 'Indian Red',
    indigo: 'Indigo',
    ivory: 'Ivory',
    khaki: 'Khaki',
    lavender: 'Lavender',
    lavenderblush: 'Lavender Blush',
    lawngreen: 'Lawn Green',
    lemonchiffon: 'Lemon Chiffon',
    lightblue: 'Light Blue',
    lightcoral: 'Light Coral',
    lightcyan: 'Light Cyan',
    lightgoldenrodyellow: 'Light Goldenrod Yellow',
    lightgray: 'Light Gray',
    lightgreen: 'Light Green',
    lightgrey: 'Light Grey',
    lightpink: 'Light Pink',
    lightsalmon: 'Light Salmon',
    lightseagreen: 'Light Sea Green',
    lightskyblue: 'Light Sky Blue',
    lightslategray: 'Light Slate Gray',
    lightslategrey: 'Light Slate Grey',
    lightsteelblue: 'Light Steel Blue',
    lightyellow: 'Light Yellow',
    lime: 'Lime',
    limegreen: 'Lime Green',
    linen: 'Linen',
    magenta: 'Magenta',
    maroon: 'Maroon',
    mediumaquamarine: 'Medium Aquamarine',
    mediumblue: 'Medium Blue',
    mediumorchid: 'Medium Orchid',
    mediumpurple: 'Medium Purple',
    mediumseagreen: 'Medium Sea Green',
    mediumslateblue: 'Medium Slate Blue',
    mediumspringgreen: 'Medium Spring Green',
    mediumturquoise: 'Medium Turquoise',
    mediumvioletred: 'Medium Violet Red',
    midnightblue: 'Midnight Blue',
    mintcream: 'Mint Cream',
    mistyrose: 'Misty Rose',
    moccasin: 'Moccasin',
    navajowhite: 'Navajo White',
    navy: 'Navy',
    neutral: 'Neutral',
    oldlace: 'Old Lace',
    olive: 'Olive',
    olivedrab: 'Olive Drab',
    orange: 'Orange',
    orangered: 'Orange Red',
    orchid: 'Orchid',
    palegoldenrod: 'Pale Goldenrod',
    palegreen: 'Pale Green',
    paleturquoise: 'Pale Turquoise',
    palevioletred: 'Pale Violet Red',
    papayawhip: 'Papaya Whip',
    peachpuff: 'Peach Puff',
    peru: 'Peru',
    pink: 'Pink',
    plum: 'Plum',
    powderblue: 'Powder Blue',
    purple: 'Purple',
    red: 'Red',
    rose: 'Rose',
    rosybrown: 'Rosy Brown',
    royalblue: 'Royal Blue',
    saddlebrown: 'Saddle Brown',
    salmon: 'Salmon',
    sandybrown: 'Sandy Brown',
    seagreen: 'Sea Green',
    seashell: 'Seashell',
    sienna: 'Sienna',
    silver: 'Silver',
    sky: 'Sky',
    skyblue: 'Sky Blue',
    slate: 'Slate',
    slateblue: 'Slate Blue',
    slategray: 'Slate Gray',
    slategrey: 'Slate Grey',
    snow: 'Snow',
    springgreen: 'Spring Green',
    steelblue: 'Steel Blue',
    stone: 'Stone',
    tan: 'Tan',
    teal: 'Teal',
    thistle: 'Thistle',
    tomato: 'Tomato',
    turquoise: 'Turquoise',
    violet: 'Violet',
    wheat: 'Wheat',
    white: 'White',
    whitesmoke: 'White Smoke',
    yellow: 'Yellow',
    yellowgreen: 'Yellow Green',
    zinc: 'Zinc',
  },
}

// General Config
export const itemsPerPage = 3

// Modals
export const openModal = (setIsOpen: Function): void => setIsOpen(true)
export const closeModal = (setIsOpen: Function): void => setIsOpen(false)

export const handleDiscardButton = ({
  setInitialValues,
  initialValues,
  setDisableSending,
  setIsEditing,
  setIsOpen,
}: {
  setInitialValues: Function
  initialValues: Record<string, string>
  setDisableSending: Function
  setIsEditing: Function
  setIsOpen: Function
}): void => {
  setInitialValues(initialValues)
  setDisableSending(false)
  closeModal(setIsOpen)
  setIsEditing(false)
}

const formatTraits = (traits: ITraitIterator[]): { labels: string[]; data: string[] } => {
  interface TData {
    label: string
    value: string
  }

  let values: TData[] = []

  traits.forEach((trait) => {
    if (trait === undefined || trait.value === 0) return

    values.push({
      label: trait.name,
      value: trait.value.toString(),
    })
  })

  values = values.sort((a, b) => a.label.localeCompare(b.label))

  const labels = values.map((item) => item.label)
  const data = values.map((item) => item.value)

  return { labels, data }
}

export interface IGraphDataset {
  label: string
  id: string
  data: string[]
  fill: boolean
  backgroundColor: string
  pointBackgroundColor: string
  borderColor: string
  pointStyle: string
  hidden: boolean
  entityType: string
}
export interface IRadarGraphData {
  data: {
    labels: string[]
    datasets: IGraphDataset[]
  }
}

export const populateGraph = ({
  id,
  traits,
  label,
  color = '',
  type,
}: {
  id: string
  traits: ITraitIterator[]
  label: string
  color: string
  type: keyof typeof PLOT_TYPES
}): IRadarGraphData => {
  const { labels, data } = formatTraits(traits)

  const borderColor = color.includes('#')
    ? color
    : colorListForGraphics[color as keyof typeof colorListForGraphics][500]
  const backgroundColor = transparentize(borderColor, 0.95)

  return {
    data: {
      labels,
      datasets: [
        {
          id,
          label,
          data,
          fill: true,
          backgroundColor,
          borderColor,
          pointBackgroundColor: borderColor,
          hidden: false,
          pointStyle: 'circle',
          entityType: type,
        },
      ],
    },
  }
}

export const populateGraphChildren = (
  dataGraphics: IFactorIteratorChildrens[],
  profileGraphSelected: IProfileGraph
): IRadarGraphData | any => {
  try {
    const dataFiltered = dataGraphics
      .map((item) => {
        return {
          ...item,
          factors: item.factors.filter((factor: IFactorIterator) =>
            profileGraphSelected.factors.includes(factor.keyword)
          ),
        }
      })
      .filter((item) => item.factors.length > 0)

    if (dataFiltered.length === 0) {
      return {
        data: {
          labels: [],
          datasets: [],
        },
      }
    }

    // Init the min and max value
    let minValue = Math.min(...dataFiltered.map((item) => item.factors[0].value))
    let maxValue = Math.max(...dataFiltered.map((item) => item.factors[0].value))

    // Getting all the labels from factors
    const labels = dataFiltered[0].factors.map((trait) => trait.name).sort((a, b) => a.localeCompare(b))

    // Getting all the values from factors
    const labelsAndValues = dataFiltered.map((item) => {
      return {
        id: item.id,
        label: item.label,
        color: item.color,
        type: item.type,
        arrayValues: item.factors
          .map((trait) => {
            return {
              label: trait.name,
              value: trait.value.toString(),
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      }
    })

    const datasets = labelsAndValues
      .map((item) => {
        const data = item.arrayValues.map((obj) => obj.value)

        // Zero values
        if (data.reduce((acc, curr) => +acc + +curr, 0) === 0) {
          return {
            label: '',
            data: [],
          }
        }

        // Min and Max values
        const minValueTemp = Math.min(...data.map((v) => +v))
        const maxValueTemp = Math.max(...data.map((v) => +v))

        if (minValueTemp < minValue) {
          minValue = minValueTemp
        }

        if (maxValueTemp > maxValue) {
          maxValue = maxValueTemp
        }

        const borderColor = item.color.includes('#')
          ? item.color
          : colorListForGraphics[item.color as keyof typeof colorListForGraphics][500]
        const backgroundColor = transparentize(borderColor, 0.95)

        return {
          id: item.id,
          label: item.label,
          data,
          fill: true,
          borderColor,
          backgroundColor,
          pointBackgroundColor: borderColor,
          pointStyle: 'circle',
          hidden: true,
          entityType: item.type,
        }
      })
      .filter((dataset) => dataset.label !== '')

    return {
      data: {
        min: Math.ceil(minValue),
        max: Math.round(maxValue),
        labels,
        datasets,
      },
    }
  } catch (error) {
    return {
      data: {
        labels: [],
        datasets: [],
      },
    }
  }
}

// Documents
export const minWords: number = 800
export const maxWords: number = 1000
export const recommendedWords: number = 1000

export const wordCounter = (words: string): number => {
  if (!words) return 0

  const regex = /\p{L}+/giu
  const wordCount = words.match(regex) ?? []

  return wordCount.length
}

export const AllowedWords = (wordNumber: number): boolean => {
  if (wordNumber < minWords) return false

  return true
}

// Redirects
export const redirectObjectWhenNotAccess = {
  redirect: {
    destination: '/404',
    permanent: false,
  },
}

// Exports
export const exportToExcel = async ({
  token,
  id,
  type,
  translator,
}: {
  token: string
  id: string | undefined
  type: string
  translator: TFunction
}): Promise<void> => {
  const notification = toast(translator('common:export-excel.notification.title'), {
    icon: '🚀',
    type: 'info',
    autoClose: false,
  })

  let status = 'success' as TypeOptions
  let message = translator('common:export-excel.notification.onSuccess')

  try {
    let response = null

    switch (type) {
      case 'area':
        response = await HumanAPI.getExcels({ accessToken: token, areaId: id })
        break
      case 'group':
        response = await HumanAPI.getExcels({ accessToken: token, groupId: id })
        break
      case 'tag':
        response = await HumanAPI.getExcels({ accessToken: token, tagId: id })
        break
      default:
        response = await HumanAPI.getExcels({ accessToken: token })
        break
    }

    if (response.status === 'KO' || response.data === null) {
      message = translator('common:export-excel.notification.notEnoughData')
      status = 'error'
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data.content]))
      const element = document.createElement('a')

      element.href = url
      element.download = `${response.data.fileName as string}`
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  } catch (error) {
    message = translator('common:export-excel.notification.onError')
    status = 'error'
  }

  toast.update(notification, {
    icon: status === 'success' ? '🥳' : '🚨',
    render: message,
    type: status,
    isLoading: false,
    autoClose: 2000,
  })
}

// Random codes
export const generateRandomCode = (type: string): string => {
  const types: Record<string, string> = {
    A: 'A',
    G: 'G',
    P: 'P',
    D: 'D',
  }

  const prefix = types[type] ?? ''

  let firstPart = ''
  let secondPart = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numbers = '0123456789'
  const charactersLength = characters.length
  const numbersLength = numbers.length

  for (let i = 0; i < 3; i++) {
    firstPart += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  for (let i = 0; i < 3; i++) {
    secondPart += numbers.charAt(Math.floor(Math.random() * numbersLength))
  }

  return `${prefix}-${firstPart}-${secondPart}`
}

export const formatFactors = ({
  factorsValues,
  factors,
}: {
  factorsValues: IFactors
  factors: IFactorShort[]
}): IFactorFormatted[] => {
  if (!factorsValues) return []

  return factors.map((factor: IFactorShort) => {
    const { name, keyword } = factor
    const value = factorsValues[keyword as keyof IFactors]

    return {
      keyword,
      name,
      value,
    }
  })
}

export const graphicsDataFormatted = ({
  factorsValues,
  factors,
}: {
  factorsValues: IFactors | null
  factors: IFactorShort[]
}): IFactorFormatted[] => (factorsValues ? formatFactors({ factorsValues, factors }) : [])

// Server side functions
export const getUserData = async (context: GetServerSidePropsContext): Promise<JWT | null> => {
  return await getToken({
    req: context.req,
    secret: process.env.JWT_SECRET,
  })
}

export const formatDate = ({
  date,
  format,
}: {
  date: string | null
  format: 'DD/MM/YYYY HH:mm' | 'YYYY-MM-DD HH:mm' | 'DD/MM/YYYY' | 'YYYY-MM-DD' | 'YYYY-MM-DDTHH:mm'
}) => {
  if (!date) return ''

  return dayjs(date).format(format)
}

export const formatTime = ({ time }: { time: string | undefined }) => {
  if (!time || time === undefined) return ''

  const [hours, minutes, seconds] = time.split(':')
  const newDate = dayjs().set('hour', +hours).set('minute', +minutes).set('second', +seconds)
  const offsetTimezone = newDate.utcOffset() * 60 * 1000

  return dayjs(newDate).add(offsetTimezone).format('HH:mm:ss')
}
