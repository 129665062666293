import clsx from 'clsx'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const CustomTooltip = (props: any): JSX.Element => {
  const { className, ...restProps } = props
  return (
    <ReactTooltip
      className={`max-w-xs rounded-md bg-gray-800 p-2 text-sm text-gray-100 dark:bg-custom-black-100 dark:text-gray-800 ${
        className as string
      }`}
      place="top"
      {...restProps}
    />
  )
}
export const Tooltip = (props: any) => {
  const { className, ...restProps } = props
  return (
    <ReactTooltip
      className={clsx(
        'max-w-[35ch] !rounded-lg !bg-gray-700 !text-sm dark:!bg-gray-200 dark:text-gray-800',
        className ?? ''
      )}
      id="tooltip-element"
      {...restProps}
    />
  )
}

export default CustomTooltip
