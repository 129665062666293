import { API_CODE_ERRORS, API_ERRORS, API_STATUS_TYPES } from '@/constants/api'
import {
  type ICredentials,
  type IDocumentLink,
  type IDocumentLinkAndDocument,
  type IDocumentLinkUpdate,
  type IPersonLink,
  type IPersonLinkAndDocument,
  type IPersonLinkUpdate,
  type TAreaCreate,
  type TAreaUpdate,
  type TDocumentCreate,
  type TDocumentUpdate,
  type TGroupCreate,
  type TGroupUpdate,
  type TPersonCreate,
  type TPersonUpdate,
  type TProfileCreate,
  type TProfileUpdate,
  type TTagCreate,
  type TTagUpdate,
  type TUserCreate,
  type TUserUpdate,
} from '@/types'
import { fetcher, handlerError } from '@/utils'

const API_BASE = process.env.NEXT_PUBLIC_API_BASE_URL ?? 'http://localhost:3000'
const debug = process.env.NEXT_PUBLIC_DEBUG === 'true'

// Authentication endpoints
const authenticationEndpoints = {
  doLogin: async ({ username, password }: ICredentials) => {
    try {
      const payload = { username, password }
      const response = await fetcher(`${API_BASE}/auth/jwt/login`, 'POST', payload, {}, false)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  doRefresh: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/auth/jwt/refresh`,
        'POST',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  recoverPassword: async (email: string) => {
    try {
      const response = await fetcher(`${API_BASE}/auth/forgot-password`, 'POST', { email }, {}, true)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  resetPassword: async (token: string, password: string) => {
    try {
      const response = await fetcher(`${API_BASE}/auth/reset-password`, 'POST', { token, password }, {}, true)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  requestVerifyToken: async (email: string) => {
    try {
      const response = await fetcher(`${API_BASE}/auth/request-verify-token`, 'POST', { email }, {}, true)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  verifyToken: async (token: string) => {
    try {
      const response = await fetcher(`${API_BASE}/auth/verify`, 'POST', { token }, {}, true)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  permissions: async ({ accessToken, type, itemId }: { accessToken: string; type: string; itemId: string }) => {
    const typeParams: Record<string, string> = {
      manage: `manage/${itemId}`,
      access: `access/${itemId}`,
      view: `view/${itemId}`,
    }

    try {
      const response = await fetcher(
        `${API_BASE}/me/${typeParams[type]}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
}

// Organizations endpoints
const organizationsEndpoints = {
  getYourOrganization: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/organization`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getOrganizationAdmins: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/organization/admins?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      let data = await response.json()

      debug && console.log({ data })

      let statusCode = response.status
      let status = response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO

      if (response.status === 401) {
        status = API_STATUS_TYPES.OK
        statusCode = 200
        data = {
          data: [],
        }
      }

      return {
        status,
        statusCode,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updateOrganizationAdmins: async (accessToken: string, admins: string[]) => {
    try {
      const response = await fetcher(
        `${API_BASE}/organization/admins`,
        'PATCH',
        { managers: admins },
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getOrganizationRankings: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/organization/ranking`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      let data = await response.json()

      debug && console.log({ data })

      let statusCode = response.status
      let status = response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO

      if (response.status === 401) {
        status = API_STATUS_TYPES.OK
        statusCode = 200
        data = null
      }

      return {
        status,
        statusCode,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getTree: async (accessToken: string) => {
    try {
      const areas = await organizationsAreasEndpoints.getAllAreas(accessToken)
      const areasData = areas.data

      const groups = await organizationGroupsEndpoints.getAllGroups({ accessToken })
      const groupsData = groups.data

      return {
        status: [areas.status, groups.status].includes(API_STATUS_TYPES.KO) ? API_STATUS_TYPES.KO : API_STATUS_TYPES.OK,
        statusCode: [areas.statusCode, groups.statusCode].includes(200) ? 200 : 500,
        data: {
          areas: areasData.data,
          groups: groupsData.data,
        },
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
}

const organizationsAreasEndpoints = {
  getAllAreas: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getAreaById: async ({ accessToken, id }: { accessToken: string; id: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${id}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  deleteAreaById: async ({ accessToken, id }: { accessToken: string; id: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${id}`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createArea: async ({ accessToken, areaData }: { accessToken: string; areaData: TAreaCreate }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area`,
        'POST',
        areaData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updateArea: async ({
    accessToken,
    areaData,
    areaId,
  }: {
    accessToken: string
    areaData: TAreaUpdate
    areaId: string
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${areaId}`,
        'PATCH',
        areaData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getAreaManagers: async ({ accessToken, areaId }: { accessToken: string; areaId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${areaId}/managers?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getAreaMembersWithAccess: async ({ accessToken, areaId }: { accessToken: string; areaId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${areaId}/members_with_access?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getAreaRankings: async ({ accessToken, areaId }: { accessToken: string; areaId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${areaId}/ranking`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updateAreaManagers: async ({
    accessToken,
    areaId,
    managers,
  }: {
    accessToken: string
    areaId: string
    managers: string[]
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${areaId}/managers`,
        'PATCH',
        {
          managers,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getAreaMembersCanManage: async ({ accessToken, areaId }: { accessToken: string; areaId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${areaId}/managers?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  deleteArea: async ({ accessToken, areaId }: { accessToken: string; areaId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/area/${areaId}`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
}

const organizationGroupsEndpoints = {
  getAllGroups: async ({ accessToken }: { accessToken: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getGroupById: async ({ accessToken, id }: { accessToken: string; id: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group/${id}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getGroupsByParentId: async ({ accessToken, parentId }: { accessToken: string; parentId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group?parent_id=${parentId}&skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createGroup: async ({ accessToken, groupData }: { accessToken: string; groupData: TGroupCreate }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group`,
        'POST',
        groupData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updateGroup: async ({
    accessToken,
    groupData,
    groupId,
  }: {
    accessToken: string
    groupData: TGroupUpdate
    groupId: string
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group/${groupId}`,
        'PATCH',
        groupData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getGroupManagers: async ({ accessToken, groupId }: { accessToken: string; groupId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group/${groupId}/managers?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getGroupMembersWithAccess: async ({ accessToken, groupId }: { accessToken: string; groupId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group/${groupId}/members_with_access?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getGroupRankings: async ({ accessToken, groupId }: { accessToken: string; groupId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group/${groupId}/ranking`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updateGroupManagers: async ({
    accessToken,
    groupId,
    managers,
  }: {
    accessToken: string
    groupId: string
    managers: string[]
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group/${groupId}/managers`,
        'PATCH',
        {
          managers,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  deleteGroup: async ({ accessToken, groupId }: { accessToken: string; groupId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/group/${groupId}`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
}

const organizationPersonsEndpoints = {
  getPersonById: async ({ accessToken, id }: { accessToken: string; id: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person/${id}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )

      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getPersonSex: async ({ accessToken }: { accessToken: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/settings/sex`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getPersonsByParentId: async ({
    accessToken,
    parentId,
    skip = 0,
    limit = 0,
  }: {
    accessToken: string
    parentId: string
    skip?: number
    limit?: number
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person?parent_id=${parentId}&skip=${skip}&limit=${limit}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getPersonsByTags: async ({ accessToken, tag }: { accessToken: string; tag: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person?tags=${tag}&skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getPersonsRankingByTag: async ({ accessToken, tag }: { accessToken: string; tag: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/tag/${tag}/ranking`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getPersonsByQuery: async ({ accessToken, query }: { accessToken: string; query: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person?q=${query}&skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createPerson: async ({ accessToken, personData }: { accessToken: string; personData: TPersonCreate }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person`,
        'POST',
        personData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updatePerson: async ({
    accessToken,
    personData,
    personId,
  }: {
    accessToken: string
    personData: TPersonUpdate
    personId: string
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person/${personId}`,
        'PATCH',
        personData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  deletePerson: async ({ accessToken, personId }: { accessToken: string; personId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person/${personId}`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getPersonByToken: async ({ token }: { token: string }) => {
    try {
      const response = await fetcher(`${API_BASE}/person/token/${token}`, 'GET', {}, true)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createPersonToken: async ({
    accessToken,
    personId,
    personData,
  }: {
    accessToken: string
    personId: string
    personData: IPersonLink
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person/${personId}/token/`,
        'POST',
        personData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  deletePersonToken: async ({ accessToken, personId }: { accessToken: string; personId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person/${personId}/token`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createPersonTokenAndDocument: async ({
    accessToken,
    tokenData,
  }: {
    accessToken: string
    tokenData: IPersonLinkAndDocument
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/person/token`,
        'POST',
        tokenData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updatePersonToken: async ({ token, tokenData }: { token: string; tokenData: IPersonLinkUpdate }) => {
    try {
      const response = await fetcher(`${API_BASE}/person/token/${token}`, 'PATCH', tokenData, {}, true)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
}

const organizationDocumentsEndpoints = {
  getDocumentsByPersonId: async ({ accessToken, parentId }: { accessToken: string; parentId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document?parent_id=${parentId}&skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createDocument: async ({ accessToken, documentData }: { accessToken: string; documentData: TDocumentCreate }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document`,
        'POST',
        documentData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updateDocument: async ({
    accessToken,
    documentData,
    documentId,
  }: {
    accessToken: string
    documentData: TDocumentUpdate
    documentId: string
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/${documentId}`,
        'PATCH',
        documentData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  deleteDocument: async ({ accessToken, documentId }: { accessToken: string; documentId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/${documentId}`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  analyzeDocument: async ({ accessToken, documentId }: { accessToken: string; documentId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/${documentId}/analyze`,
        'POST',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      if (response.status === API_CODE_ERRORS.FREE_LIMIT_EXCEEDED) {
        return {
          status: API_STATUS_TYPES.OK,
          statusCode: API_CODE_ERRORS.FREE_LIMIT_EXCEEDED,
          error: API_ERRORS[API_CODE_ERRORS.FREE_LIMIT_EXCEEDED],
          data: null,
        }
      }

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getHelpers: async ({ accessToken }: { accessToken: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/helper`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createDocumentToken: async ({
    accessToken,
    documentId,
    documentData,
  }: {
    accessToken: string
    documentId: string
    documentData: IDocumentLink
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/${documentId}/token`,
        'POST',
        documentData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  deleteDocumentToken: async ({ accessToken, documentId }: { accessToken: string; documentId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/${documentId}/token`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createDocumentTokenAndDocument: async ({
    accessToken,
    tokenData,
  }: {
    accessToken: string
    tokenData: IDocumentLinkAndDocument
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/token`,
        'POST',
        tokenData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updateDocumentToken: async ({ token, tokenData }: { token: string; tokenData: IDocumentLinkUpdate }) => {
    try {
      const response = await fetcher(`${API_BASE}/document/token/${token}`, 'PATCH', tokenData, {}, true)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getDocumentByToken: async ({ token }: { token: string }) => {
    try {
      const response = await fetcher(`${API_BASE}/document/token/${token}`, 'GET', {}, {}, true)
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  regenerateReport: async ({ accessToken, documentId }: { accessToken: string; documentId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/${documentId}/report`,
        'PATCH',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getDocumentReport: async ({
    accessToken,
    documentId,
    profileId,
    lang,
  }: {
    accessToken: string
    documentId: string
    profileId: string
    lang: string
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/document/${documentId}/report/${profileId}/${lang}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
}

const organizationTagsEndpoints = {
  getTagById: async ({ accessToken, tagId }: { accessToken: string; tagId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/tag/${tagId}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getTagList: async ({ accessToken }: { accessToken: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/tag`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ response, data, accessToken })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data: data.data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  createTag: async ({ accessToken, tagData }: { accessToken: string; tagData: TTagCreate }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/tag`,
        'POST',
        tagData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  updateTag: async ({ accessToken, tagData, tagId }: { accessToken: string; tagData: TTagUpdate; tagId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/tag/${tagId}`,
        'PATCH',
        tagData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  deleteTag: async ({ accessToken, tagId }: { accessToken: string; tagId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/tag/${tagId}`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
}

// Settings
const settingsEndpoints = {
  getLanguagesSettings: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/settings/languages`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
  getEmailTemplatesSettings: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/settings/email-templates`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err: any) {
      return handlerError(err)
    }
  },
}

// Users
const userEndpoints = {
  getUsersFromMyOrganization: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/user?skip=0&limit=0`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  getMe: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/me`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      console.error(err)
      return {
        status: API_STATUS_TYPES.KO,
        error: err,
      }
    }
  },
  updateMe: async (accessToken: string, userData: TUserUpdate) => {
    try {
      const response = await fetcher(
        `${API_BASE}/me`,
        'PATCH',
        userData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  createUser: async ({ accessToken, userData }: { accessToken: string; userData: TUserCreate }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/user`,
        'POST',
        userData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  updateUser: async ({
    accessToken,
    userData,
    userId,
  }: {
    accessToken: string
    userData: TUserUpdate
    userId: string
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/user/${userId}`,
        'PATCH',
        userData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
}

// Factors
const factorsEndpoints = {
  getAllFactorsTranslated: async ({ accessToken, language = 'es' }: { accessToken: string; language: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/factor/translations/${language}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()
      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  getAllFactors: async ({ accessToken, language = 'es' }: { accessToken: string; language: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/factor/${language}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()
      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  getFactorsShort: async ({ token, lang }: { token: string; lang: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/factor/${lang}`,
        'GET',
        {},
        {
          Authorization: `Bearer ${token}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
}

const profilesEndpoints = {
  getProfilesReport: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/profile/all`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  getProfiles: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/profile/`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  createProfile: async ({ accessToken, profileData }: { accessToken: string; profileData: TProfileCreate }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/profile`,
        'POST',
        profileData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  updateProfile: async ({
    accessToken,
    profileId,
    profileData,
  }: {
    accessToken: string
    profileId: string
    profileData: TProfileUpdate
  }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/profile/${profileId}`,
        'PATCH',
        profileData,
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  deleteProfile: async ({ accessToken, profileId }: { accessToken: string; profileId: string }) => {
    try {
      const response = await fetcher(
        `${API_BASE}/profile/${profileId}`,
        'DELETE',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
  getProfilesGraph: async (accessToken: string) => {
    try {
      const response = await fetcher(
        `${API_BASE}/profile/graph/all`,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const data = await response.json()

      debug && console.log({ data })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data,
      }
    } catch (err) {
      return handlerError(err)
    }
  },
}

// Exports
const exportsEndpoints = {
  getExcels: async ({
    accessToken,
    groupId,
    areaId,
    tagId,
    organizationId,
  }: {
    accessToken: string
    groupId?: string
    areaId?: string
    tagId?: string
    organizationId?: string
  }) => {
    let URL = `${API_BASE}/person/excel`
    if (groupId !== undefined) URL += `?group_id=${groupId}`
    if (areaId !== undefined) URL += `?area_id=${areaId}`
    if (tagId !== undefined) URL += `?tags=${tagId}`
    if (organizationId !== undefined) URL += `?organization_id=${organizationId}`

    try {
      const response = await fetcher(
        URL,
        'GET',
        {},
        {
          Authorization: `Bearer ${accessToken}`,
        },
        true
      )
      const content = await response.blob()
      const pattern = /(?<=").+?(?=")/
      const fileName = pattern.exec(response.headers.get('Content-Disposition') ?? '')?.toString() ?? 'export.xlsx'

      debug &&
        console.log({
          data: {
            content,
            fileName,
          },
        })

      return {
        status: response.ok ? API_STATUS_TYPES.OK : API_STATUS_TYPES.KO,
        statusCode: response.status,
        data: {
          content,
          fileName,
        },
      }
    } catch (err) {
      return handlerError(err)
    }
  },
}

const HumanAPI = {
  ...authenticationEndpoints,
  ...organizationsEndpoints,
  ...organizationsAreasEndpoints,
  ...organizationGroupsEndpoints,
  ...organizationPersonsEndpoints,
  ...organizationDocumentsEndpoints,
  ...organizationTagsEndpoints,
  ...userEndpoints,
  ...settingsEndpoints,
  ...factorsEndpoints,
  ...profilesEndpoints,
  ...exportsEndpoints,
}

export default HumanAPI
