import { signOut } from 'next-auth/react'

export class APIError extends Error {
  readonly statusCode: number

  constructor({ statusCode, message }: { statusCode?: number; message: string }) {
    super(message)
    this.name = 'APIError'
    this.statusCode = statusCode ?? 500
  }
}

export class APIUnauthorizedError extends Error {
  constructor({ message }: { message: string }) {
    super(message)
    this.name = 'APIUnauthorizedError'
  }
}

export const ERROR_API_HANDLING = Object.freeze({
  401: ({ message }: { message: string }) => {
    ERROR_HANDLING.APIUnauthorizedError()
      .then(() => {})
      .catch(() => {})
    throw new APIUnauthorizedError({
      message: message || 'Unauthorized',
    })
  },
  403: ({ message }: { message: string }) => {
    throw new APIError({
      statusCode: 403,
      message: message || 'Forbidden',
    })
  },
  404: ({ message }: { message: string }) => {
    throw new APIError({
      statusCode: 404,
      message: message || 'Not Found',
    })
  },
  409: ({ message }: { message: string }) => {
    throw new APIError({
      statusCode: 409,
      message: message || 'Conflict',
    })
  },
  500: ({ message }: { message: string }) => {
    throw new APIError({
      statusCode: 500,
      message: message || 'Internal Server Error',
    })
  },
  0: ({ message }: { message: string }) => {
    throw new APIError({
      statusCode: 0,
      message: message || 'Error occurred while fetching data',
    })
  },
})

export const ERROR_HANDLING = Object.freeze({
  APIUnauthorizedError: async () => {
    try {
      // NOTE: This is a workaround to remove the token from the localStorage
      // and redirect the user to the login page.

      // IMPORTANT: If you don't remove the token from the localStorage, the
      // user will be redirected to the login page in an infinite loop, because
      // the token is not valid anymore and the API will return a 401 error
      if (typeof window === 'undefined') return

      localStorage.removeItem('token')
      await signOut({ callbackUrl: '/' })
    } catch (error) {
      console.log('ERROR_HANDLING.APIUnauthorizedError', error)
    }
  },
})

export const LIST_OF_ERRORS = Object.freeze({
  APIUnauthorizedError: 'APIUnauthorizedError',
})
