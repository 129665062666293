// Hooks
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState, type Dispatch, type SetStateAction } from 'react'

// Types
import { getCountries } from '@/modules/settings/data/application/get/getCountries'
import { type Country } from '@/modules/settings/data/domain/Country'
import { type SettingsRepository } from '@/modules/settings/data/domain/SettingsRepository'

export const useLocalStorage = (key: string, initialValue: string): any[] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item !== null ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any): void => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

// export const readLocalStorage = (key: string): any => {
//   if (typeof window !== 'undefined') {
//     try {
//       // Get from local storage by key
//       const item = window.localStorage.getItem(key)
//       console.log({ item })
//       // Parse stored json or if none return initialValue
//       return item !== null ? JSON.parse(item) : null
//     } catch (error) {
//       // If error also return initialValue
//       console.log(error)
//       return null
//     }
//   } else {
//     return null
//   }
// }

type TmodalHandlersType = 'OPEN_MANAGERS_MODAL'

export interface GlobalContextInitialProps {
  children: React.ReactNode
  repositories: {
    settingsRepository: SettingsRepository
  }
}
export interface IGlobalContextProps {
  loading: boolean
  themeStyle: string
  pageData: any
  token: string | null
  modalsHandlers: {
    OPEN_MANAGERS_MODAL: Dispatch<SetStateAction<boolean>> | null
  }
  setThemeStyle: () => void
  setLoading: (loading: boolean) => void
  setPageData: (pageData: any) => void
  setToken: (token: string) => void
  setModalsHandlers: ({
    type,
    handler,
  }: {
    type: TmodalHandlersType
    handler: Dispatch<SetStateAction<any>> | null
  }) => void
  countriesCode: Country[]
}

export const GlobalContext = React.createContext<IGlobalContextProps>({
  loading: true,
  themeStyle: 'light',
  pageData: {},
  token: null,
  modalsHandlers: {
    OPEN_MANAGERS_MODAL: null,
  },
  countriesCode: [],
  setThemeStyle: () => {},
  setLoading: () => {},
  setPageData: () => {},
  setToken: () => {},
  setModalsHandlers: () => {},
})

export const GlobalContextProvider = ({ children, repositories }: GlobalContextInitialProps): JSX.Element => {
  const [localStorageThemeStyle, setLocalStorageThemeStyle] = useLocalStorage('themeStyle', 'light')

  const { i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [pageData, setPageData] = useState(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)

  const customSetPageData = (pageData: any): void => setPageData(pageData)

  const [currentThemeStyle, setCurrentThemeStyle] = useState(localStorageThemeStyle)
  const setThemeStyle = (): void => {
    if (currentThemeStyle === 'light') {
      setCurrentThemeStyle('dark')
      setLocalStorageThemeStyle('dark')
    } else {
      setCurrentThemeStyle('light')
      setLocalStorageThemeStyle('light')
    }
  }

  const customSetToken = (token: string): void => {
    if (accessToken === null) setAccessToken(token)
  }

  const [modalsHandlers, setModalsHandlers] = useState({
    OPEN_MANAGERS_MODAL: null,
  })

  const customSetModalsHandlers = ({
    type,
    handler,
  }: {
    type: string
    handler: Dispatch<SetStateAction<any>> | null
  }): void => {
    setModalsHandlers({
      ...modalsHandlers,
      [type]: handler,
    })
  }

  const [countriesCode, setCountriesCode] = useState<Country[]>([])

  useEffect(() => {
    setIsLoading(true)

    if (i18n.language === undefined) return

    getCountries(repositories.settingsRepository)({ lang: i18n.language })
      .then((countries) => {
        setCountriesCode(countries)
      })
      .catch((error) => {
        console.log({ error })
        setCountriesCode([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [i18n.language])

  return (
    <GlobalContext.Provider
      value={{
        loading: isLoading,
        themeStyle: currentThemeStyle,
        pageData,
        token: accessToken,
        modalsHandlers,
        countriesCode,
        setThemeStyle,
        setLoading: setIsLoading,
        setPageData: customSetPageData,
        setToken: customSetToken,
        setModalsHandlers: customSetModalsHandlers,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
