import { API_BASE, debug, fetcher } from '@/modules/shared/infrastructure/fetchData'
import { type Country } from '../domain/Country'
import { type SettingsRepository } from '../domain/SettingsRepository'

const API_URLS = Object.freeze({
  getCountries: ({ lang }: { lang: string }) => `${API_BASE}/settings/country-codes/${lang}`,
})

export const createApiSettingsRepository = (): SettingsRepository => {
  return {
    getCountries,
  }
}

const getCountries = async ({ lang }: { lang: string }): Promise<Country[]> => {
  const response = await fetcher(API_URLS.getCountries({ lang }), 'GET', {}, {}, true)

  const data = await response.json()

  // convert json to array
  const array = Object.entries(data).map(([key, value]) => ({
    code: key,
    name: value,
  }))

  debug && console.log('getCountries', array)

  if (!response.ok) throw new Error(response.statusText)

  return array as Country[]
}
